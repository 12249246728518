.copyrights-trans {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
    background-color: transparent;
    padding: 30px 0;
    color: #fff;
    font-size: 14px;
    font-weight: 250;
    line-height: 1;
    text-transform: auto;
}
