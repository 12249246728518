.services-section {
    background-color: #f8f8f8;
    padding: 2rem 1rem 0 1rem;
    width: 100%;
    justify-content: center;
}

.services-section .container {
    margin: auto;
    width: 1360px;
    padding-bottom: 3rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.services-section .container img {
    width: 90%;
}

.services-section .col-2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
}

.services-section .col-2 .line {
    margin-left: 0;
    margin-right: auto;
    width: 100%;
}

.services-section .col-2 h2 {
    font-size: 3rem;
    margin-left: 0;
    margin-right: auto;
    margin-bottom: 1rem;
}

.services-section .col-2 p {
    margin: 1rem 0 1rem 0;
}

.services-section .col-2 ul {
    padding-top: 1rem;
    list-style: circle;
    padding-left: 2rem;
}


@media screen and (max-width:1250px) {
    .services-section .container {
        grid-template-columns: 1fr;
    }

    .services-section .container {
        width: 100%;
    }

    .services-section .container img {
        padding: 0rem;
        width: 100%;
    }

    .services-section .container .col-2 {
        padding: 0;
    }

    .services-section .container .col-2  h2 {
        font-size: 2rem;
        line-height: 2.1rem;
        padding-top: 30px;
    }

    .services-section .container .col-2  p {
        font-size: .8rem;
        text-align: justify;
        width: 100%;
    }


}

