.copyrights-bg {
    position: relative;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
    background-color: rgba(0, 60, 165, 0.9);
    padding: 30px 0;
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    text-transform: auto;
}

@media screen and (max-weight:940px) {
    .copyrights-bg {
        font-weight: 900;
    }
}
