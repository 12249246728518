footer {
  position: relative;
  padding: 1rem 0 0 0;
}

.contact-footer {
  background-color: var(--overlay);
  height: 40vh;
  width: auto;
  position: relative;
  overflow: hidden;
  padding: 3rem;
}

.contact-footer-bg {
  position: absolute;
  top: 10%;
  left: 10%;
  height: 50%;
  width: 50%;
  transform: translate(-10%, -10%);
  z-index: -1;
}

.contact-btn-align {
  display: flex;
  justify-content: center;
  padding: 1rem 0 0 0;
  z-index: 1;
}

.button {
  margin: auto;
}

.button:hover {
  border: var(--primary-dark);
  background-color: var(--primary-dark);
}

.contact-footer-bg img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 100%;
  min-height: 100%;
}

.line {
  width: 25%;
}

.contact-footer ul {
  display: flex;
  justify-content: center;
}

.contact-footer li {
  padding: 5rem 0.8rem 0.9rem 0.8rem;
}

.contact-footer li a {
  color: #fff;
  text-transform: capitalize;
  font-size: 1.1rem;
  font-weight: 500;
}

.contact-footer li a:hover {
  padding-bottom: 12px;
}

@media screen and (max-width: 940px) {
  .contact-btn-align {
    padding: 1rem 0 0 0;
  }

  .contact-footer li a {
    font-size: 0.7rem;
  }

  .contact-footer li {
    padding: 5rem 1rem 0 0.8rem;
  }

  .contact-footer li a:hover {
    padding-bottom: 1rem;
  }

  .line {
    width: 100%;
  }
}
