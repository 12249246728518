.home-hero {
    background-color: var(--overlay);
    color: #fff;
    height: 100vh;
    width: auto;
    position: relative;
    overflow: hidden;
}

.home-hero-video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    z-index: -1;
}

.home-hero-video video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 100%;
    min-height: 100%;
}

.home-hero .home-hero-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    max-width: 1100px;
    margin: auto;
    padding: 1rem;
}

.home-hero .home-hero-content h1 {
    font-size: 70px;
}

.home-hero .home-hero-content p {
    font-size: 1.4rem;
    font-weight: 250;
    padding: 0 10rem 0 10rem;
    margin-top: 1.5rem;
}

.home-hero .button {
    margin: 4rem 0;
    width: 200px;
    background-color: var(--primary-color);
    border: 1px solid var(--primary-color);
    color: #fff;
    font-size: 1.1rem;
}

.home-hero .button:hover {
    background-color: var(--secondary-dark);
    border: 1px solid var(--secondary-dark);
}

@media screen and (max-width:940px) {
    .home-hero .home-hero-content h1 {
        font-size: 2.8rem;
    }

    .home-hero .home-hero-content p {
        font-size: 1rem;
        padding: 0 2rem;
    }

    
}










