.form-container {
  display: flex;
  justify-content: center;
  padding-top: 5rem;
  padding-bottom: 5rem;
  background-color: rgb(209, 230, 250);
}

.form {
  width: auto;
  background-color: #6db0f8;
  border-radius: 5px;
  box-shadow: 4px 4px 10px rgba(80, 80, 80, 0.419);
}

.field-align-col {
  right: 0;
  left: 0;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.field-align-row {
  display: grid;
  justify-content: center;
  grid-template-columns: 1fr;
}

.field-align-row textarea {
  height: 10rem;
  max-width: 100%;
}

.form-control {
  color: #505050;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 25px;
  height: 45px;
  text-transform: none;
  border: none;
  background: #fff;
  padding-left: 24px;
  margin: 2rem 1rem auto 1rem;
  position: relative;
  border-radius: 6px;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.button {
  font-size: 1rem;
  margin: 2rem auto;
}

@media screen and (max-width: 740px) {
  .form-container .form .field-align-col {
    grid-template-columns: 1fr;
  }
}
