.header {
  position: fixed;
  height: auto;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  transition: 0.3s ease-in;
  overflow: hidden;
}

.header-bg {
  background-color: rgba(0, 60, 165, 1);
}

.header .navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1360px;
  margin: auto;
  height: 100%;
  padding: 15px 1rem;
}

.header .nav-menu a {
  color: #ffffff;
}

.header .navbar img {
  width: 280px;
  height: auto;
}

.header .nav-menu {
  display: flex;
}

.header .nav-item {
  padding: 1rem;
  font-size: 1.1rem;
  font-weight: 600;
  text-transform: capitalize;
}

.header .nav-item a:hover {
  padding-bottom: 10px;
  border-bottom: 2px solid #ffffff;
}

.hamburger {
  display: none;
}

@media only screen and (max-width: 940px) {
  .header {
    max-width: 100%;
  }

  .header .navbar {
    max-width: 100%;
  }

  .hamburger {
    display: block;
  }

  .nav-menu {
    position: fixed;
    left: -100%;
    top: 90px;
    flex-direction: column;
    background-color: rgba(10, 70, 135, 0.9);
    width: 100%;
    height: 90vh;
    z-index: 999;
    text-align: center;
    transition: 0.3s;
  }

  .nav-menu a {
    font-weight: 900;
  }

  .nav-menu.active {
    left: 0;
  }

  .nav-item {
    margin: 1.5rem 0;
  }

  .header .navbar img {
    width: 230px;
  }
}

/* Apple Watch */
@media (max-device-width: 42mm) and (min-device-width: 38mm) {
  .header {
    max-width: 100%;
  }

  .header .navbar {
    max-width: 100%;
  }

  .navbar .logo img {
    width: 300px;
  }

  .hamburger {
    display: block;
  }

  .nav-menu {
    position: fixed;
    left: -100%;
    top: 90px;
    flex-direction: column;
    background-color: rgba(10, 70, 135, 0.9);
    width: 100%;
    height: 90vh;
    z-index: 999;
    text-align: center;
    transition: 0.3s;
  }

  .nav-menu a {
    font-weight: 500;
  }

  .nav-menu.active {
    left: 0;
  }

  .nav-item {
    margin: 1.5rem 0;
  }
}
