.content {
  background-color: rgba(10, 23, 99, 0.8);
  height: 100vh;
  overflow: hidden;
  position: relative;
  -webkit-box-align: center;
  -moz-box-align: center;
  box-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -o-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  display: flex;
  display: flex;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  box-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -o-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
}
.content .content-box {
  width: auto;
  position: relative;
}

.content .content-box h1 {
    color: #FFFFFF;
    text-align: center;
}

.content .content-box p {
    color: #FFFFFF;
    text-align: center;
    padding: 1rem 0;
}

.content .content-box .big-content {
  position: relative;
  width: 22rem;
  min-height: 22rem;
  margin: 0 auto 3rem;
}
.content .content-box .big-content .list-square {
  float: left;
}
.content .content-box .big-content .list-line {
  float: left;
  margin-left: 2rem;
}
.content .content-box .big-content span.square {
  display: block;
  background: transparent;
  width: 5rem;
  height: 5rem;
  -webkit-border-radius: 0.5rem;
  -moz-border-radius: 0.5rem;
  -ms-border-radius: 0.5rem;
  border-radius: 0.5rem;
  border: 1rem solid #FFFFFF;
  margin-bottom: 1.5rem;
}
.content .content-box .big-content span.line {
  display: block;
  background: #FFFFFF;
  width: 15rem;
  height: 1rem;
  -webkit-border-radius: 0.5rem;
  -moz-border-radius: 0.5rem;
  -ms-border-radius: 0.5rem;
  border-radius: 0.5rem;
  margin-bottom: 2.3rem;
}
.content .content-box .big-content span.line:nth-child(2) {
  width: 10rem;
}
.content .content-box .big-content span.line:nth-child(4) {
  width: 10rem;
}
.content .content-box .big-content span.line:nth-child(6) {
  width: 10rem;
}
.content .content-box .big-content .fa-search {
  position: absolute;
  top: 10rem;
  left: 15rem;
  font-size: 10rem;
  color: #00c8aa;
  -webkit-animation: corner 5s infinite;
  -moz-animation: corner 5s infinite;
  animation: corner 5s infinite;
}
.content .content-box .big-content .fa-search.color {
  color: #FF0084;
}

@-webkit-keyframes corner {
  0% {
    -webkit-transform: translate(-2rem, 0);
    -webkit-animation-timing-function: 0, 0.02, 0, 1.01;
  }
  20% {
    -webkit-transform: translate(-15rem, 2rem);
  }
  40% {
    -webkit-transform: translate(-7rem, -7rem);
    animation-timing-function: cubic-bezier(0, 0.02, 0, 1.01);
  }
  60% {
    -webkit-transform: translate(-15rem, -10rem);
  }
  80% {
    -webkit-transform: translate(2rem, -12rem);
  }
  100% {
    -webkit-transform: translate(-2rem, 0);
  }
}
@-moz-keyframes corner {
  0% {
    -moz-transform: translate(-2rem, 0);
    -moz-animation-timing-function: 0, 0.02, 0, 1.01;
  }
  20% {
    -moz-transform: translate(-15rem, 2rem);
  }
  40% {
    -moz-transform: translate(-7rem, -7rem);
    animation-timing-function: cubic-bezier(0, 0.02, 0, 1.01);
  }
  60% {
    -moz-transform: translate(-15rem, -10rem);
  }
  80% {
    -moz-transform: translate(2rem, -12rem);
  }
  100% {
    -moz-transform: translate(-2rem, 0);
  }
}
@keyframes corner {
  0% {
    -webkit-transform: translate(-2rem, 0);
    -moz-transform: translate(-2rem, 0);
    -ms-transform: translate(-2rem, 0);
    -o-transform: translate(-2rem, 0);
    transform: translate(-2rem, 0);
    -webkit-animation-timing-function: 0, 0.02, 0, 1.01;
    -moz-animation-timing-function: 0, 0.02, 0, 1.01;
    animation-timing-function: 0, 0.02, 0, 1.01;
  }
  20% {
    -webkit-transform: translate(-15rem, 2rem);
    -moz-transform: translate(-15rem, 2rem);
    -ms-transform: translate(-15rem, 2rem);
    -o-transform: translate(-15rem, 2rem);
    transform: translate(-15rem, 2rem);
  }
  40% {
    -webkit-transform: translate(-7rem, -7rem);
    -moz-transform: translate(-7rem, -7rem);
    -ms-transform: translate(-7rem, -7rem);
    -o-transform: translate(-7rem, -7rem);
    transform: translate(-7rem, -7rem);
    animation-timing-function: cubic-bezier(0, 0.02, 0, 1.01);
  }
  60% {
    -webkit-transform: translate(-15rem, -10rem);
    -moz-transform: translate(-15rem, -10rem);
    -ms-transform: translate(-15rem, -10rem);
    -o-transform: translate(-15rem, -10rem);
    transform: translate(-15rem, -10rem);
  }
  80% {
    -webkit-transform: translate(2rem, -12rem);
    -moz-transform: translate(2rem, -12rem);
    -ms-transform: translate(2rem, -12rem);
    -o-transform: translate(2rem, -12rem);
    transform: translate(2rem, -12rem);
  }
  100% {
    -webkit-transform: translate(-2rem, 0);
    -moz-transform: translate(-2rem, 0);
    -ms-transform: translate(-2rem, 0);
    -o-transform: translate(-2rem, 0);
    transform: translate(-2rem, 0);
  }
}


/* ------------------------------------- */
/* 4. Media Queries .................... */
/* ------------------------------------- */
/* Notebook devices @media only screen and (max-width: 1200px) */
/* Medium Devices, Desktops @media only screen and (max-width: 992px) */
/* Small Devices, Tablets @media only screen and (max-width: 768px) */
/* Extra Small Devices, Phones @media only screen and (max-width: 480px) */
@media only screen and (max-width: 480px) {

  .content {
    height: auto;
    display: block;
    padding: 3rem 0 5rem;
  }
  .content .content-box {
    padding: 0 1rem;
  }
  .content .content-box .big-content {
    -webkit-transform: scale(0.8);
    -moz-transform: scale(0.8);
    -ms-transform: scale(0.8);
    -o-transform: scale(0.8);
    transform: scale(0.8);
    margin: 0 auto;
  }

  h1 {
    font-size: 2rem;
    line-height: 1.5;
  }

  footer {
    position: relative;
    padding-bottom: 5rem;
  }
  footer ul li {
    width: 100%;
    margin-bottom: 1rem;
  }
  footer ul li::after {
    display: none;
  }
  footer ul li a {
    padding: 0;
  }
  footer ul li a::after {
    display: none;
  }

  #particles-js {
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
  }
}
/* Only for tablet in landscape mode @media only screen and (max-device-width: 1024px) and (orientation: landscape) */
/* Only for phone in landscape mode @media screen and (max-device-width: 667px) and (orientation: landscape) */
@media screen and (max-device-width: 667px) and (orientation: landscape) {
  .content {
    height: auto;
    display: block;
    padding: 5rem 0 5rem;
  }
  .content .content-box {
    padding: 0 1rem;
  }
  .content .content-box .big-content {
    -webkit-transform: scale(0.8);
    -moz-transform: scale(0.8);
    -ms-transform: scale(0.8);
    -o-transform: scale(0.8);
    transform: scale(0.8);
    margin: 0 auto;
  }

}
