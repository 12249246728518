.other-hero {
    background-color: var(--overlay);
    color: #fff;
    height: 60vh;
    width: auto;
    position: relative;
    overflow: hidden;
}

.other-hero-video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    z-index: -1;
    overflow: hidden;
}

.other-hero-video video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 100%;
    min-height: 100%;
}

.other-hero .other-hero-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    max-width: 1100px;
    margin: auto;
    padding: 5rem 2rem 0 2rem;
}

.other-hero .other-hero-content h1 {
    font-size: 65px;
}

.other-hero .other-hero-content p {
    font-size: 1.4rem;
    font-weight: 250;
    padding: 0 10rem 0 10rem;
    margin-top: 1.5rem;
}

@media screen and (max-width:940px) {
    .other-hero .other-hero-content h1 {
        font-size: 2.5rem;
        line-height: 2.5rem;
    }

    .other-hero .other-hero-content p {
        font-size: .9rem;
        padding: 0 1rem 1rem 0;
    }

    
}










