.about-section {
  background-color: #f8f8f8;
  margin: auto;
  padding: 2rem 1rem 0 1rem;
  width: 100%;
}

.container {
  width: 1240px;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.container img {
  width: 90%;
}

.col-2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;
}

.col-2 h2 {
  font-size: 2rem;
}

.col-2 p {
  margin: 2rem 0;
}

.row-2 {
  width: 100%;
  margin-top: 2rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.row-2 h2 {
  margin-bottom: 1rem;
  text-align: right;
  padding-right: 4rem;
}

.row-2 ul {
  display: table;
  table-layout: auto;
  width: 100%;
  margin-bottom: 2rem;
  padding-left: 1rem;
}

.row-2 li {
  display: table-row;
}

.row-2 li span {
  display: table-cell;
  text-align: left;
}

@media (max-width: 1250px) {
  .container {
    grid-template-columns: 1fr;
    width: 100%;
  }

  .row-2 {
    grid-template-columns: 1fr;
  }

  .row-2 {
    grid-template-columns: 1fr;
  }

  .container img {
    padding: 0rem;
    width: 100%;
  }

  .col-2 {
    padding: 0;
  }

  .col-2 h2 {
    line-height: 2rem;
    padding-top: 30px;
  }

  .col-2 p {
    font-size: 0.8rem;
    text-align: justify;
    width: 100%;
  }

  .row-2 h2 {
    text-align: left;
  }

  .row-2 ul {
    font-size: 0.8rem;
    margin-left: -1rem;
    line-height: 1.2rem;
  }
}
